<template>
  <v-container>
    <!-- Pantalla si no existen sucursales aún -->
    <v-row
      v-if="isBranchesEmpty"
      justify="center"
      class="d-flex justify-center align-center text-center"
      style="min-height: 80vh"
    >
      <v-col cols="12" md="8" class="px-10">
        <v-img
          src="@/assets/store.png"
          contain
          max-height="120"
          alt="Store"
          class="mb-3"
        ></v-img>

        <p class="text-body-2 my-5 px-8">
          Crea las sucursales y almacenes que conforman tu cadena desde aquí
        </p>

        <v-btn
          rounded
          block
          outlined
          color="primary"
          class="py-6 border-3 text-none text-body-1 font-weight-bold"
          style="border-width: 3px"
          @click="addBranch"
        >
          <v-icon left>mdi-plus</v-icon>
          Agregar sucursal o bodega
        </v-btn>
      </v-col>
    </v-row>

    <!-- Sucursales existentes -->
    <v-row>
      <div v-if="!isBranchesEmpty" class="mt-5 px-5" style="width: 100%">
        <v-col
          v-if="branches.length <= branchLimit"
          cols="12"
          md="4"
          class="d-flex"
        >
          <v-btn
            rounded
            block
            outlined
            color="primary"
            class="py-6 border-3 text-none text-body-1 font-weight-bold"
            style="border-width: 3px"
            @click="addBranch"
          >
            <v-icon left>mdi-plus</v-icon>
            Agregar sucursal
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex"
          v-for="(branch, index) in branches"
          :key="index"
        >
          <v-card class="pa-5 bgcards rounded-xl" style="width: 100%">
            <v-card-title class="d-flex justify-space-between align-center">
              <v-row>
                <v-col cols="8">
                  <h3 class="text-subtitle-2 font-weight-bold">
                    {{ branch.nombre }}
                  </h3>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    text
                    class="font-weight-bold primary--text text-none mt-n3"
                    @click="goto('edit-branch', branch.id)"
                  >
                    Editar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text class="text-start">
              <p>
                Tipo: <br /><strong>{{ branch.tipo_sucursal }}</strong>
              </p>
              <p>
                Dirección: <br /><strong>{{ branch.calle }}</strong>
              </p>
              <p>
                Teléfono: <br /><strong>{{ branch.telefono }}</strong>
              </p>

              <p>Empleados asociados:</p>
              <ul>
                <li
                  v-for="(employee, empIndex) in branch.employees"
                  :key="empIndex"
                >
                  <strong>{{ employee.name }}</strong> - {{ employee.position }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-if="branches.length > branchLimit"
          cols="12"
          md="4"
          class="mb-10"
        >
          <p>
            Haz llegado al límite de sucursales que estan incluidas en tu
            <strong>Plan Básico, PAT</strong>para poder agregar más, contacta a
            tu Asesor PAT.
          </p>
          <v-btn
            rounded
            block
            outlined
            color="primary"
            class="py-6 border-3 text-body-1 font-weight-bold text-none"
            style="border-width: 3px"
            @click="openPatAssistance"
          >
            <v-icon size="28" left>mdi-help-circle-outline</v-icon>
            Asistencia PAT
          </v-btn>
        </v-col>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { getSucursales } from "../services/auth";
export default {
  data() {
    return {
      // branches: [],
      branches: [
        {
          id: 1,
          businessType: "Farmacia",
          businessCategory: "branch",
          name: "Sucursal Central",
          street: "Av. Principal 123",
          PhoneNumber: "5551234567",
          employees: [
            { name: "Juan Pérez", position: "Gerente" },
            { name: "Ana López", position: "Cajera" },
          ],
        },
      ],
      branchLimit: 5,
    };
  },
  computed: {
    isBranchesEmpty() {
      return this.branches.length === 0;
    },
  },
  methods: {
    goto(route, id) {
      this.$router.push({ name: route, params: { id: id } });
    },
    addBranch() {
      this.$router.push({ name: "create-branch" });
    },
    openPatAssistance() {
      console.log("Asistencia PAT...");
    },
  },
  async created() {
    const response = await getSucursales();
    if (response.success) {
      this.branches = response.sucursales;
    } else {
      this.$toast({
        message: "Ocurrió un error al obtener las sucursales",
        color: "error",
        timeout: 2000,
      });
      console.log("Error al obtener sucursales");
    }
  },
};
</script>
